.swal2-container{
  z-index: 10000!important;
}

/* Estilo da barra de rolagem */
::-webkit-scrollbar {
  width: 10px; /* Largura da barra de rolagem */
}

/* Estilo do trilho da barra de rolagem */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor do trilho */
}

/* Estilo do indicador da barra de rolagem */
::-webkit-scrollbar-thumb {
  background: #888; /* Cor do indicador */
  border-radius: 5px; /* Raio das bordas */
}

/* Estilo do indicador quando hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Cor do indicador ao passar o mouse */
}

